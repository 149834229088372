class Institucion{
    constructor(id, razon_social, cuit, domicilio, localidad, provincia, codigo_postal, email, telefono, estado){
        this.id = id;
        this.razon_social = razon_social;
        this.cuit = cuit;
        this.domicilio = domicilio;
        this.localidad = localidad;
        this.provincia = provincia;
        this.codigo_postal = codigo_postal;
        this.email = email;
        this.telefono = telefono;
        this.estado = estado;
    }
}

export default Institucion;